exports.components = {
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/pages/storyblok-preview.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js": () => import("./../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/templates/StoryblokDefaultTemplate.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-demo-1-jsx": () => import("./../../../src/pages/demo/1.jsx" /* webpackChunkName: "component---src-pages-demo-1-jsx" */),
  "component---src-pages-demo-2-jsx": () => import("./../../../src/pages/demo/2.jsx" /* webpackChunkName: "component---src-pages-demo-2-jsx" */),
  "component---src-pages-demo-index-jsx": () => import("./../../../src/pages/demo/index.jsx" /* webpackChunkName: "component---src-pages-demo-index-jsx" */),
  "component---src-pages-demo-workspace-jsx": () => import("./../../../src/pages/demo/workspace.jsx" /* webpackChunkName: "component---src-pages-demo-workspace-jsx" */),
  "component---src-pages-demo-yum-clipped-rects-js": () => import("./../../../src/pages/demo/yum-clipped-rects.js" /* webpackChunkName: "component---src-pages-demo-yum-clipped-rects-js" */),
  "component---src-pages-demo-yum-jsx": () => import("./../../../src/pages/demo/yum.jsx" /* webpackChunkName: "component---src-pages-demo-yum-jsx" */),
  "component---src-pages-demo-yum-overlays-js": () => import("./../../../src/pages/demo/yum-overlays.js" /* webpackChunkName: "component---src-pages-demo-yum-overlays-js" */),
  "component---src-pages-demo-yum-super-hero-jsx": () => import("./../../../src/pages/demo/yum-super-hero.jsx" /* webpackChunkName: "component---src-pages-demo-yum-super-hero-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

